import {useSignalingApi} from '@wix/live-video-components'
import {useTranslation} from '@wix/yoshi-flow-editor'
import React, {useEffect, useRef} from 'react'
import {getReactionCode} from '../../selectors/reactions'
import {useActions, useWidgetState} from '../widget-state-provider'
import {isChatOpened} from '../../selectors/settings'
import {isWebinar} from '../../selectors/session'
import {NotificationType} from '../../actions/notifications'

const SCHEDULE_JOIN_NOTIFICATIONS_DELAY = 5000

export const SessionListener: React.FC = () => {
  const {
    listParticipants,
    updateParticipant,
    fetchChatHistory,
    receiveChatMessage,
    deleteChatMessage,
    receiveReaction,
    pushNotification,
    listReactions,
    setParticipantActivity,
    setParticipantStatus,
    setAudio,
    scheduleJoinedNotification,
    enableJoinNotifications,
    disableJoinNotifications,
    setJoinError,
  } = useActions()

  const {
    onReceiveChatMessage,
    onReceiveParticipant,
    onReceiveParticipantStatus,
    onReceiveReaction,
    onReceiveDeleteChatMessage,
    onSoftMute,
    onJoinError,
  } = useSignalingApi()

  const {t} = useTranslation()
  const sidePanel = useWidgetState(state => state.sidePanel)
  const webinar = useWidgetState(isWebinar)
  const chatOpened = isChatOpened(sidePanel)
  const joinNotificationsTimer = useRef(null)

  useEffect(() => {
    listParticipants()
    listReactions()
    fetchChatHistory()
  }, [])

  useEffect(
    () =>
      onReceiveChatMessage(message => {
        receiveChatMessage({message})
        if (!chatOpened) {
          pushNotification({
            id: message.id,
            participantId: message.participantId,
            type: NotificationType.TEXT,
            prependName: true,
            text: message.body,
            visible: true,
          })
        }
      }),
    [chatOpened],
  )

  useEffect(() => onReceiveDeleteChatMessage(deleteChatMessage), [])

  useEffect(
    () =>
      onReceiveReaction(reaction => {
        receiveReaction(reaction)
        pushNotification({
          id: reaction.id,
          participantId: reaction.participantId,
          type: NotificationType.REACTION,
          prependName: true,
          text: t('notification.reactionText'),
          reactionCode: getReactionCode(reaction.emoji),
          visible: true,
        })
      }),
    [],
  )

  useEffect(() => {
    const debouncedEnableJoinNotifications = () => {
      clearTimeout(joinNotificationsTimer.current)
      joinNotificationsTimer.current = null

      joinNotificationsTimer.current = setTimeout(() => {
        enableJoinNotifications()
        joinNotificationsTimer.current = null
      }, SCHEDULE_JOIN_NOTIFICATIONS_DELAY)
    }

    debouncedEnableJoinNotifications()

    const destroyOnReceiveParticipant = onReceiveParticipant(({participantId, present}) => {
      if (present) {
        scheduleJoinedNotification(participantId)

        if (joinNotificationsTimer.current) {
          debouncedEnableJoinNotifications()
        }
      }

      setParticipantActivity({participantId, present})
      updateParticipant({participantId})
    })

    return () => {
      destroyOnReceiveParticipant()
      clearTimeout(joinNotificationsTimer.current)
      disableJoinNotifications()
    }
  }, [])

  useEffect(() => {
    const receiveParticipantStatusDestructor = webinar ? null : onReceiveParticipantStatus(setParticipantStatus)

    return () => {
      receiveParticipantStatusDestructor?.()
    }
  }, [webinar])

  useEffect(() => onSoftMute(() => setAudio(false)), [])

  useEffect(() => onJoinError(() => setJoinError()), [])

  return null
}
