import {SignalingApiProvider} from '@wix/live-video-components'
import React, {useEffect, useMemo, useState} from 'react'
import {useEnvironment, useWixSdk, useExperiments} from '@wix/yoshi-flow-editor'
import {createApi} from '../services/api'
import {usePrevious} from '../utils/hooks'
import {WidgetApiContext} from './components/api-provider'
import {Container} from './components/container'
import {LinkExpired} from './components/link-expired'
import {MediaDevicesProvider} from './components/media-devices-provider/media-devices-provider'
import {MobileVideo} from './components/mobile-video'
import {SessionListener} from './components/session-listener'
import {StatusScreen, StatusScreenType} from './components/status-screen'
import {Video} from './components/video'
import {WaitingRoom} from './components/waiting-room'
import {MobileWaitingRoom} from './components/waiting-room/mobile-waiting-room'
import {WidgetActionsContext, WidgetAuthorizationContext, WidgetStateContext} from './components/widget-state-provider'
import {GetCurrentComponentArgs, WidgetProps} from './interfaces'
import {
  hasLeftSession,
  isSessionEnded,
  isParticipantBanned,
  isSessionConnected,
  isParticipantJoinedElsewhere,
  isParticipantRejoining,
} from './selectors/session'
import {SessionError} from './types/errors'
import {UnexpectedError} from './components/unexpected-error'
import {Modal} from './components/modals/modal'

const getCurrentComponent = ({
  mobile,
  connected,
  ended,
  leftSession,
  forcedScreen,
  participantBanned,
  participantJoinedElsewhere,
  rejoining,
}: GetCurrentComponentArgs) => {
  if (forcedScreen === 'live-video-screen') {
    return mobile ? <MobileVideo /> : <Video />
  }

  if (forcedScreen === 'end-screen') {
    return <StatusScreen type={StatusScreenType.SESSION_ENDED} />
  }

  if (participantBanned || forcedScreen === 'banned') {
    return <StatusScreen type={StatusScreenType.BANNED} />
  }

  if (participantJoinedElsewhere) {
    return <StatusScreen type={StatusScreenType.JOINED_ELSEWHERE} />
  }

  if (leftSession && !rejoining) {
    return <StatusScreen type={StatusScreenType.LEFT_SESSION} />
  }

  if (ended) {
    return <StatusScreen type={StatusScreenType.SESSION_ENDED} />
  }

  if (!connected && !rejoining) {
    return mobile ? <MobileWaitingRoom /> : <WaitingRoom />
  }

  return mobile ? <MobileVideo /> : <Video />
}

export default (props: WidgetProps) => {
  const error = props?.session?.error

  if (error === SessionError.NO_SESSION || !props?.session?.session?.id) {
    return <LinkExpired navigateToMainPage={props.actions.navigateToMainPage} />
  } else if (error === SessionError.JOIN_FAILURE) {
    return <UnexpectedError />
  }

  return <App {...props} />
}

const App: React.FC<WidgetProps> = ({id, actions, authorization, baseUrl, ...state}) => {
  const {Wix} = useWixSdk()
  const connected = isSessionConnected(state)
  const ended = isSessionEnded(state)
  const leftSession = hasLeftSession(state)
  const participantBanned = isParticipantBanned(state)
  const participantJoinedElsewhere = isParticipantJoinedElsewhere(state)
  const rejoining = isParticipantRejoining(state)
  const {isMobile, isEditor, isSSR, isPreview} = useEnvironment()
  const [forcedScreen, setForcedScreen] = useState('')
  const participant = state.session.participant
  const prevParticipant = usePrevious(participant)

  const api = useMemo(() => createApi({baseUrl, getAuthorization: () => authorization}), [baseUrl, authorization])

  const {experiments, ready: experimentsReady} = useExperiments()

  useEffect(() => {
    experimentsReady && actions.setExperiments(experiments.all())
  }, [experimentsReady])

  useEffect(() => {
    if (Wix && isEditor) {
      Wix.addEventListener('SETTINGS_UPDATED', event => {
        if (event?.type === 'forceShow') {
          setForcedScreen(event.screen)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (prevParticipant && !participant && !ended && !leftSession) {
      setForcedScreen('banned')
    } else if (ended) {
      setForcedScreen('end-screen')
    }
  }, [participant, ended, leftSession])

  return (
    <WidgetStateContext.Provider value={state}>
      <WidgetActionsContext.Provider value={actions}>
        <WidgetAuthorizationContext.Provider value={authorization}>
          <WidgetApiContext.Provider value={api}>
            <SignalingApiProvider>
              <MediaDevicesProvider ssr={isSSR} previewMode={isEditor || isPreview || ended}>
                <Container forcedScreen={forcedScreen} id={id}>
                  {connected && !rejoining ? <SessionListener /> : null}
                  {getCurrentComponent({
                    mobile: isMobile,
                    connected,
                    ended,
                    leftSession,
                    forcedScreen,
                    participantBanned,
                    participantJoinedElsewhere,
                    rejoining,
                  })}
                </Container>
                <Modal />
              </MediaDevicesProvider>
            </SignalingApiProvider>
          </WidgetApiContext.Provider>
        </WidgetAuthorizationContext.Provider>
      </WidgetActionsContext.Provider>
    </WidgetStateContext.Provider>
  )
}
