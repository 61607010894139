import {getAvatarColor} from '@wix/live-video-commons'
import classNames from 'classnames'
import React from 'react'
import {AvatarSize} from 'wix-ui-tpa/Avatar'
import {DH} from '../../../../../data-hooks'
import {
  getParticipantName,
  isParticipantHidden,
  getParticipant,
  isParticipantRecorder,
} from '../../../../selectors/participants'
import {Avatar} from '../../../common/avatar'
import {useWidgetState} from '../../../widget-state-provider'
import {NotificationImage, NotificationType} from '../../../../actions/notifications'
import {Recording} from '../../../icons/recording'
import s from './notification.scss'
import {NotificationProps} from '.'

export const Notification: React.FC<NotificationProps> = ({
  notification: {text, type, prependName, reactionCode, visible, fading, participantId, image},
  mobile,
}) => {
  const {participants} = useWidgetState(state => state.participants)
  const participantName = getParticipantName(participants, participantId)
  const participant = getParticipant(participants, participantId)
  const participantHidden = isParticipantHidden(participant)
  const participantRecorder = isParticipantRecorder(participant)

  if (!visible || (participantHidden && !participantRecorder)) {
    return null
  }

  return (
    <div className={classNames(s.notification, {[s.mobile]: mobile, [s.fading]: fading})} data-hook={DH.NOTIFICATION}>
      {image === NotificationImage.RECORDING ? (
        <div className={s.recording}>
          <Recording />
        </div>
      ) : (
        <Avatar
          colorId={getAvatarColor(participantId)}
          className={s.avatar}
          size={mobile ? AvatarSize.xxSmall : AvatarSize.xSmall}
          name={participantName}
        />
      )}
      {prependName && (
        <div data-hook={DH.NOTIFICATION_PARTICIPANT_NAME} className={s.nameContainer}>
          <span className={s.name}>{participantName}</span>
        </div>
      )}
      <div data-hook={DH.NOTIFICATION_TEXT} className={s.textContainer}>
        <span className={s.text}>{text}</span>
      </div>
      {type === NotificationType.REACTION && (
        <div
          data-hook={DH.NOTIFICATION_REACTION}
          className={s.emoji}
          dangerouslySetInnerHTML={{__html: reactionCode}}
        />
      )}
    </div>
  )
}
