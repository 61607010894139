import {useBi} from '@wix/yoshi-flow-editor'
import {SessionType} from '@wix/ambassador-livevideo-server/types'
import {useWidgetState} from '../components/widget-state-provider'
import {isSessionConnected} from '../selectors/session'

export const useWidgetBI = () => {
  const biLogger = useBi()
  const {session} = useWidgetState(state => state.session)
  const {biToken, visitorId} = useWidgetState(state => state.environment)
  const meeting_id = session.id
  const video_conferencing_type =
    (session.sessionType && (session.sessionType === SessionType.MEETING ? 'meeting' : 'stream')) || null
  const visitor_id = visitorId
  const {appDefId, entityId, entityType, instanceId} = session.entityReference || {}
  const origin = useWidgetState(isSessionConnected) ? 'meeting' : 'waiting_room'

  const getCommonParams = () => ({
    app_id: appDefId,
    app_instance_id: instanceId,
    entity_id: entityId,
    entity_name: entityType,
    meeting_id,
    biToken,
    video_conferencing_type,
    visitor_id,
  })

  return {
    uouLiveVideoWaitingScreenLoaded: () => biLogger.uouLiveVideoWaittingScreenLoaded(getCommonParams()),
    uouLiveVideoJoinClick: () => biLogger.uouLiveVideoJoinClick(getCommonParams()),
    uouLiveVideoMicrophoneToggle: (choice: Choice) =>
      biLogger.uouLiveVideoMicrophoneToggle({...getCommonParams(), choice, origin}),
    uouLiveVideoCameraToggle: (choice: Choice) =>
      biLogger.uouLiveVideoCameraToggle({...getCommonParams(), choice, origin}),
    uouLiveVideoShareScreenToggle: (choice: Choice) =>
      biLogger.uouLiveVideoShareScreenToggle({...getCommonParams(), choice}),
    uouLiveVideoChangeLayout: (layout_type: 'speaker' | 'grid') =>
      biLogger.uouLiveVideoChangeLayout({...getCommonParams(), layout_type}),
    uouLiveVideoSettingsToggle: (choice: Choice) => biLogger.uouLiveVideoSettingsToggle({...getCommonParams(), choice}),
    uouLiveVideoParticipantsListToggle: (choice: Choice) =>
      biLogger.uouLiveVideoParticipantsListToggle({...getCommonParams(), choice}),
    uouLiveVideoChatScreenToggle: (choice: Choice) =>
      biLogger.uouLiveVideoChatScreenToggle({...getCommonParams(), choice}),
    uouLiveVideoChatMessagePublish: () => biLogger.uouLiveVideoChatMessagePublish(getCommonParams()),
    uouLiveVideoReactionPublish: () => biLogger.uouLiveVideoReactionPublish(getCommonParams()),
    uouLiveVideoLeaveClick: () => biLogger.uouLiveVideoLeaveClick({...getCommonParams()}),
    uouLiveVideoExitScreenLoaded: (reason: UouLiveVideoExitScreenLoadedReason) =>
      biLogger.uouLiveVideoExitScreenLoaded({...getCommonParams(), reason}),
    uouLiveVideoGoToSiteClick: () => biLogger.uouLiveVideoGoToSiteClick({...getCommonParams()}),
    uouLiveVideoRejoinClick: () => biLogger.uouLiveVideoRejoinClick({...getCommonParams()}),
  }
}

export type UouLiveVideoExitScreenLoadedReason = 'end' | 'leave' | 'banned'
type Choice = 'on' | 'off'
