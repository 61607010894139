import classNames from 'classnames'
import React, {useEffect} from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {unblockAutoPlay} from '@wix/live-video-components'
import {LAYOUT} from '@wix/live-video-commons'
import {useWidgetBI} from '../../hooks/bi'
import {isWebinar, isSessionLoaded} from '../../selectors/session'
import {SidePanel} from '../side-panel'
import {useWidgetState} from '../widget-state-provider'
import {useRecorder} from '../../hooks/recorder'
import {useActions} from '../widget-state-provider/widget-state-provider'
import {useStreamer} from '../../hooks/streamer'
import {ErrorPopup} from './error-popup'
import {ErrorPopupProps} from './error-popup/interfaces'
import {Preview} from './preview'
import {WaitingForm} from './waiting-form'
import s from './waiting-room.scss'
import {WaitingRoomProps} from '.'

export const WaitingRoom: React.FC<WaitingRoomProps> = () => {
  const webinar = useWidgetState(isWebinar)
  const sessionLoaded = useWidgetState(isSessionLoaded)
  const {uouLiveVideoWaitingScreenLoaded} = useWidgetBI()
  const {recorder} = useRecorder()
  const {streamer} = useStreamer()
  const {addParticipant, setLayout} = useActions()

  useEffect(() => {
    if (!recorder && sessionLoaded) {
      uouLiveVideoWaitingScreenLoaded()
    }
  }, [sessionLoaded, recorder, uouLiveVideoWaitingScreenLoaded])

  const joinBot = async () => {
    unblockAutoPlay()
    const name = recorder ? 'Video Recorder' : 'Video Streamer'
    await addParticipant({name})
    setLayout(LAYOUT.FOCUSED)
    document.body.style.overflow = 'hidden'
  }

  if (!sessionLoaded || recorder || streamer) {
    return <div className={s.botJoinOverlay} onClick={sessionLoaded ? joinBot : null}></div>
  }

  return (
    <>
      <div className={classNames(s.container, {[s.webinar]: webinar})}>
        <div className={s.content}>
          <WaitingForm />
          {!webinar && <Preview />}
        </div>
        <SidePanel className={s.sidePanel} />
      </div>
    </>
  )
}

export const ErrorPopupWrapped: React.FC<ErrorPopupProps & {asPopup?: boolean}> = ({
  title,
  lineOne,
  lineTwo,
  learnMoreUrl,
  hideRefresh,
  asPopup = false,
}) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={classNames(s.container, s.webinar, {[s.popup]: asPopup, [s.mobile]: isMobile})}>
      <div className={s.errorContent}>
        <ErrorPopup
          title={title}
          lineOne={lineOne}
          lineTwo={lineTwo}
          learnMoreUrl={learnMoreUrl}
          hideRefresh={hideRefresh}
        />
      </div>
    </div>
  )
}
