import {calculateTimeLeft, formatTimeLeft, DAY} from '@wix/live-video-commons'
import React, {useEffect, useState} from 'react'
import {SIZE} from 'wix-ui-tpa/Button'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {DH} from '../../../../../data-hooks'
import {getSession, isSessionStarted, isWebinar} from '../../../../selectors/session'
import {Clock} from '../../../icons'
import {useMediaDevicesContext} from '../../../media-devices-provider/media-devices-provider'
import {useWidgetState} from '../../../widget-state-provider'
import {Button} from './button'
import s from './button-with-status.scss'
import {ButtonWithStatusProps} from './interfaces'

export const ButtonWithStatus: React.FC<ButtonWithStatusProps> = ({onClick}) => {
  const {isMobile: mobile} = useEnvironment()
  const state = useWidgetState(widgetState => widgetState)
  const webinar = isWebinar(state)
  const sessionStarted = isSessionStarted(state)
  const {startDate} = getSession(state)
  const sessionStartDate = String(startDate)
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(sessionStartDate))
  const {IOSBrowserNotSupported} = useMediaDevicesContext()
  const {t} = useTranslation()

  const shouldShowCountdown = timeLeft <= DAY && timeLeft > 0
  const shouldHideStatus = timeLeft > DAY

  useEffect(() => {
    const interval =
      shouldShowCountdown &&
      setInterval(() => {
        setTimeLeft(calculateTimeLeft(sessionStartDate))
      }, 1000)

    return () => clearInterval(interval)
  }, [])

  const getStatusText = () => {
    if (sessionStarted) {
      return t(`${webinar ? 'webinar' : 'meeting'}Started`)
    }

    if (shouldShowCountdown) {
      return formatTimeLeft(timeLeft)
    }

    return t(`${webinar ? 'webinar' : 'meeting'}HostWillStartSoon`)
  }

  const disabled = !sessionStarted || IOSBrowserNotSupported

  return (
    <div>
      {!shouldHideStatus && (
        <div className={s.meetingStatus}>
          <Clock />
          <div className={s.meetingText}>{getStatusText()}</div>
        </div>
      )}
      <Button
        className={classNames(s.button, {[s.buttonDisabled]: disabled})}
        size={mobile ? SIZE.medium : SIZE.large}
        fullWidth={mobile}
        disabled={disabled}
        data-hook={DH.JOIN_BUTTON}
        onClick={onClick}
      >
        {t(`join${webinar ? 'Webinar' : 'Meeting'}`)}
      </Button>
    </div>
  )
}
