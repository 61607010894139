import React from 'react'
import classNames from 'classnames'
import {useWidgetState} from '../../../widget-state-provider'
import {useApi} from '../../../api-provider'
import {DH} from '../../../../../data-hooks'
import {useWidgetBI} from '../../../../hooks/bi'
import {isAndroid} from '../../../../selectors/environment'
import s from './reaction.scss'
import {ReactionProps} from '.'

export const Reaction: React.FC<ReactionProps> = ({id, code, onClick = () => {}}) => {
  const {authorization} = useWidgetState(state => state.session)
  const api = useApi()
  const {uouLiveVideoReactionPublish} = useWidgetBI()

  const sendReaction = async () => {
    await api.sendReaction(authorization, id)
    onClick()
    uouLiveVideoReactionPublish()
  }

  return (
    <div
      data-hook={DH.REACTION}
      className={classNames(s.reaction, {[s.android]: isAndroid()})}
      dangerouslySetInnerHTML={{__html: code}}
      onClick={sendReaction}
    />
  )
}
